<template>
    <div class="module">
        <van-nav-bar title="实时视频" left-arrow  @click-left="$router.go(-1)" />
        <van-steps direction="vertical" :active="14" active-color="#1677ff" class="setps-content">
            <van-step v-for="(item,index) in devList" :key="index">
                <div class="title">
                    {{item.name}}
                </div>
                <div class="status">
                    运行状态：
                    <van-tag size="medium" type="primary" v-if="item.status">在线</van-tag>
                    <van-tag size="medium" type="warning" v-else>离线</van-tag>
                </div>
                <div class="marbtm5">
                    实时资源：<van-button type="primary" size="mini" @click="playResoure">点击观看</van-button>
                </div>
                <div>更新时间：{{item.time}}</div>
            </van-step>
        </van-steps>
        <van-dialog v-model="show" width="90%" title="实时视频" confirm-button-color="#1677FF" confirm-button-text="关闭">
            <div class="video-container">
                <video width="100%" height="180" controls></video>
            </div>
        </van-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            show:false,
            devList:[
                {name:'进京1 K79+798',status:1,time:'2021-11-01 12:30'},
                {name:'进京2 K79+650',status:1,time:'2021-11-01 08:30'},
                {name:'进京3 K79+500',status:1,time:'2021-11-01 11:30'},
                {name:'进京4 K79+350',status:1,time:'2021-11-01 09:30'},
                {name:'进京5 K79+200',status:1,time:'2021-11-01 12:30'},
                {name:'进京6 K79+050',status:1,time:'2021-11-01 02:20'},
                {name:'进京7 K78+900',status:1,time:'2021-11-01 12:30'},
                {name:'进京8 K78+750',status:1,time:'2021-11-02 12:00'},
                {name:'出京1 CK76+657',status:1,time:'2021-11-02 12:10'},
                {name:'出京2 CK76+807',status:1,time:'2021-11-02 14:30'},
                {name:'出京3 CK76+957',status:1,time:'2021-11-02 14:50'},
                {name:'出京4 CK77+057',status:1,time:'2021-11-02 15:30'},
                {name:'出京5 CK77+157',status:1,time:'2021-11-02 16:00'},
                {name:'出京6 CK77+257',status:1,time:'2021-11-02 18:10'},
                {name:'出京7 CK77+357',status:1,time:'2021-11-02 19:00'},
                {name:'出京8 CK77+457',status:0,time:'2021-11-02 19:10'},
                {name:'出京9 CK77+557',status:0,time:'2021-11-02 19:40'},
            ]
        }
    },
    methods:{
        playResoure(){
            this.show = true;
            setTimeout(()=>{
                this.$toast({
                    message: '实时视频对接中，敬请期待...',
                    position: 'bottom',
                })
            },1000)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/tunnel/module.scss';
</style>